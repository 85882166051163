/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';

import Lifts from '../lifts/lifts';
import CameraIcon from '../../assets/camera.svg';
import Alert from '../../assets/alert_danger.svg';

import styles from './slider.module.scss';

const Slider = ({ liftsInfo, alert }) => {
  const { Hls } = window;

  const video = document.getElementById('video');
  const videoSrc =
    'https://hoktastream4.webcamera.pl/kotelnica_cam_0b240f/kotelnica_cam_0b240f.stream/playlist.m3u8';
  if (Hls.isSupported()) {
    const hls = new Hls();
    hls.loadSource(videoSrc);
    hls.attachMedia(video);
  } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
    video.src = videoSrc;
  }

  return (
    <div className={styles.container}>
      {alert.length > 0 &&
        alert.map((el, index) => (
          <div className={el.type === 'WARNING' ? styles.alertWarning : styles.alert} key={index}>
            <img className={styles.alert__icon} src={Alert} alt="Alert icon" />
            <p className={styles.alert__title}>{el.title}</p>
            <p className={styles.alert__text}>{el.text}</p>
          </div>
        ))}
      <div className={styles.slider}>
        <div className={styles.camera}>
          <video className={styles.camera__player} id="video" autoPlay controls={false} />
          <div className={styles.camera__name}>
            <div className={styles.camera__box}>
              <img className={styles.camera__icon} src={CameraIcon} alt="Icon camera" />
              <h2 className={styles.camera__title}>
                Kotelnica Białczańska <div className={styles.line} />{' '}
                <Lifts liftsInfo={liftsInfo} />
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Slider;

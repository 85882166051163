/* eslint-disable no-console */
import axios from 'axios';
import { useEffect, useState } from 'react';
import { BASE_URL, STATIONS } from '../../api/config';

const useFetchLiftsInfo = () => {
  const [liftsInfo, setLiftsInfo] = useState([]);

  useEffect(() => {
    async function getLiftsInfo() {
      axios({
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
          'Access-Control-Allow-Headers':
            'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
        },

        url: `${BASE_URL}${STATIONS}`,
      })
        .then((response) => {
          setLiftsInfo(response.data[1]);
        })
        .catch((e) => {
          console.error(e);
        });
    }
    getLiftsInfo();

    const getRefreshLiftsInfo = () => {
      const minutes = new Date().getMinutes();
      if (minutes === 0 || minutes === 20 || minutes === 40) {
        getLiftsInfo();
      }
    };

    setInterval(() => {
      getRefreshLiftsInfo();
    }, 1000 * 60);
  }, []);

  return { liftsInfo };
};

export default useFetchLiftsInfo;

import React from 'react';
import useFetchWeather from 'components/hooks/useFetchWeather';
import useFetchLiftsInfo from 'components/hooks/useFetchLiftsInfo';
import useFetchAlert from 'components/hooks/useFetchAlert';

import Header from 'components/header/header';
import Slider from 'components/slider/slider';
import Weather from 'components/weather/weather';
import Footer from 'components/footer/footer';
import Advertisement from '../assets/advertisment.png';

import GlobalStyle from '../theme/GlobalStyle';
import styles from './App.module.scss';

const App = () => {
  const { weatherData } = useFetchWeather();
  const { liftsInfo } = useFetchLiftsInfo();
  const { alert } = useFetchAlert();

  return (
    <>
      <GlobalStyle />
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.background}>
            <Header weatherData={weatherData} />
            <Weather weatherData={weatherData} />
            <Footer />
          </div>
        </div>
        <div className={styles.slider}>
          <Slider liftsInfo={liftsInfo} alert={alert} />
          <div className={styles.advertisement}>
            <img className={styles.advertisement__image} src={Advertisement} alt="Reklama" />
          </div>
        </div>
      </div>
    </>
  );
};

export default App;

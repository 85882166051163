import React from 'react';

import LiftIcon from '../../assets/lifts.svg';
import SlopeIcon from '../../assets/slope.svg';

import styles from './lifts.module.scss';

const LiftsInfo = ({ liftsInfo }) => (
  <div className={styles.lifts}>
    <div className={liftsInfo.open ? styles.lifts__dot : styles.lifts__dotRed} />
    <p className={styles.lifts__paragraph}>{liftsInfo.open ? 'Otwarte' : 'Zamknięte'}</p>
    <p className={styles.lifts__paragraph}>
      <img className={styles.lifts__icon} src={LiftIcon} alt="Icon" />
      {liftsInfo.openLifts} / {liftsInfo.totalLifts}
    </p>
    <p className={styles.lifts__paragraph}>
      <img className={styles.lifts__icon} src={SlopeIcon} alt="Icon" />
      {liftsInfo.openRuns} / {liftsInfo.totalRuns}
    </p>
  </div>
);

export default LiftsInfo;

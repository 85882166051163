import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/pl';

import { getIcon } from '../../helpers/weather';
import Logo from '../../assets/logo_kotelnica.svg';
import styles from './header.module.scss';

const Header = ({ weatherData }) => {
  const date = new Date();

  return (
    <div className={styles.wrapper}>
      <div className={styles.innerWrapper}>
        <div className={styles.logo}>
          <img className={styles.logo__image} src={Logo} alt="Logo" />
        </div>
        <div className={styles.container}>
          <div>
            <p className={styles.paragraph__time}>
              <Moment interval={1000} format="HH:mm" />
            </p>
            <p className={styles.paragraph}>
              <Moment interval={1000} format="dddd, DD.MM.YYYY" />
            </p>
          </div>
          <div className={styles.weather}>
            <div>
              <p className={styles.paragraph__temperature}>{weatherData.temperature}&deg;C</p>
              <p className={styles.paragraph}>
                Odczuwalna: {weatherData.perceivedTemperature}&deg;C
              </p>
            </div>
            <img
              className={styles.weather__icon}
              src={`${process.env.PUBLIC_URL}/assets/img/weather/white/${
                date.getHours() < Number(weatherData?.sunset?.split(':')[0]) &&
                date.getHours() > Number(weatherData?.sunrise?.split(':')[0])
                  ? 'day'
                  : 'night'
              }/${getIcon(weatherData.weatherCode)}`}
              alt="Ikona pogody"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
